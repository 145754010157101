.why-us {
  margin-top: 100px;
  position: relative;

  @media screen and (max-width: $media-phone) {
    margin-top: 55px;
  }

  .back-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    @media screen and (max-width: $media-phone) {
      top: auto;
      bottom: 0;
      left: -200px;
    }

    img {
      @media screen and (max-width: $media-phone) {
        width: 429px;
      }

      @media screen and (max-width: 412px) {
        width: 400px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $media-phone) {
      flex-direction: column-reverse;
    }

    &_img {
      margin-top: 50px;

      @media screen and (max-width: $media-phone) {
        margin-top: 20px;
      }

      img {
        margin-left: -50px;

        @media screen and (max-width: $media-phone) {
          width: 100%;
          margin: 0;
        }
      }
    }

    &_text {
      .title {
        line-height: 50px;
        width: 573px;

        @media screen and (max-width: $media-phone) {
          width: 100%;
          line-height: 30px;
        }
      }

      .list {
        margin-top: 20px;

        @media screen and (max-width: $media-phone) {
          margin-top: 10px;
        }

        li {
          color: $color-gray;
          margin-top: 10px;
          line-height: 40px;
          font-size: 16px;
          font-weight: 400;
          position: relative;
          padding-left: 20px;

          @media screen and (max-width: $media-phone) {
            font-size: 14px;
            line-height: 36px;
            margin-top: 5px;
          }

          &::after {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $color-gray;
            display: block;
            position: absolute;
            left: 0;
            top: 17px;

            @media screen and (max-width: $media-phone) {
              top: 16px;
            }
          }
        }
      }

      .icon {
        margin-top: 30px;
        display: flex;
        align-items: center;

        @media screen and (max-width: $media-phone) {
          margin-top: 15px;
        }

        img {
          margin-right: 33px;
        }
      }
    }
  }
}
