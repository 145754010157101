* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  overflow-x: hidden;
}

body.active {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.button {
  border: 1px solid #ffffff;
  border-radius: 20px;

  width: 299px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  font-size: 14px;
  line-height: 44px;
  font-weight: 700;
}

.container {
  width: 1232px;
  margin: 0 auto;

  @media screen and (max-width: 1232px) {
    width: 100%;
    padding: 0 20px;
  }
}

.container-big {
  width: 1368px;
  margin: 0 auto;

  @media screen and (max-width: 1368px) {
    width: 100%;
    padding: 0 20px;
  }
}

.title {
  color: #383838;
  font-size: 38px;
  line-height: 55px;
  font-weight: 700;

  @media screen and (max-width: $media-phone) {
    font-size: 22px;
    line-height: 30px;
  }
}

.mobile-menu {
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: 0.3s;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 999;
  padding: 26px 25px 38px 80px;
  overflow-y: scroll;

  .nav {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item {
      font-size: 14px;
      color: $color-gray;
      line-height: 44px;
      font-weight: 400;
    }
  }

  .contacts {
    margin-top: 190px;
    border-top: 1px solid $color-blue;
    width: 100%;

    @media screen and (max-width: 389px) {
      margin-top: 130px;
    }

    .item {
      margin-top: 35px;
      display: flex;
      align-items: center;

      .wrapper-icon {
        background: rgba(68, 79, 180, 0.08);
        border-radius: 6px;
        padding: 6px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.mobile-menu.open {
  transform: translateX(-5%);

  @media screen and (max-width: 890px) {
    transform: translateX(-8%);
  }

  @media screen and (max-width: 625px) {
    transform: translateX(-10%);
  }

  @media screen and (max-width: 500px) {
    transform: translateX(-13%);
  }
}

.pop-up {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(129, 135, 189, 0.25);
  border-radius: 20px;
  padding: 63px 50px;
  width: 753px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 9;

  @media screen and (max-width: $media-phone) {
    width: 90%;
    padding: 30px 25px;
  }

  .linear-gradient-one {
    background: linear-gradient(
      183.89deg,
      rgba(59, 130, 246, 0.06) 8.63%,
      rgba(59, 130, 246, 0) 95.32%
    );
    border-radius: 40px;
    transform: rotate(-36.85deg);
    position: absolute;
    display: block;
    width: 500px;
    height: 500px;
    bottom: -90px;
    right: -220px;

    @media screen and (max-width: $media-phone) {
      bottom: 90px;
    }
  }

  .close {
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;

    @media screen and (max-width: $media-phone) {
      right: 10px;
      top: 10px;
    }
  }

  h6 {
    font-size: 30px;
    line-height: 44px;
    font-weight: 700;
    color: $color-gray;
    text-align: center;

    @media screen and (max-width: $media-phone) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #000000;
    margin-top: 20px;

    @media screen and (max-width: $media-phone) {
      font-size: 14px;
      line-height: 26px;
    }
  }

  ul {
    @media screen and (max-width: $media-phone) {
      margin-top: 10px;
    }

    li {
      font-size: 16px;
      line-height: 44px;
      font-weight: 400;
      color: $color-gray;
      position: relative;
      padding-left: 20px;

      @media screen and (max-width: $media-phone) {
        font-size: 14px;
        line-height: 26px;
      }

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $color-gray;
        display: block;
        position: absolute;
        left: 0;
        top: 17px;

        @media screen and (max-width: $media-phone) {
          top: 16px;
        }
      }
    }
  }
}

.pop-up-wrapper {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  background: rgba(68, 79, 180, 0.7);
}

.pop-up-wrapper.visible {
  visibility: visible;
  opacity: 1;
}

.form-wrapper {
  .content {
    background: #ffffff;
    box-shadow: 0px 8px 24px rgba(129, 135, 189, 0.25);
    border-radius: 20px;
    padding: 28px;
    width: 522px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 9;

    @media screen and (max-width: $media-phone) {
      width: 90%;
      padding: 30px 25px;
    }

    .linear-gradient-one {
      background: linear-gradient(
        274.4deg,
        rgba(59, 130, 246, 0.1) 2.39%,
        rgba(59, 130, 246, 0) 100.85%
      );
      border-radius: 40px;
      transform: rotate(-53.14deg);
      position: absolute;
      display: block;
      width: 352;
      height: 335;
      top: 70px;
      left: -170px;
      z-index: -1;

      @media screen and (max-width: $media-phone) {
        bottom: 90px;
      }
    }

    .close {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;

      @media screen and (max-width: $media-phone) {
        right: 10px;
        top: 10px;
      }
    }

    h6 {
      text-align: center;
      font-size: 24px;
      line-height: 55px;
      font-weight: 700;
      color: $color-gray;

      @media screen and (max-width: $media-phone) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .input {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        outline: none;
        margin-bottom: 16px;
        width: 100%;
        padding: 17px 0 17px 17px;
        font-size: 16px;
        font-weight: 400;
        color: $color-gray;

        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          color: $color-gray;
        }
      }

      .submit {
        background: #444fb4;
        border-radius: 20px;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: #fff;

        @media screen and (max-width: $media-phone) {
          font-size: 12px;
        }
      }
    }
  }
}

.button-to-top {
  display: none;
  position: fixed;
  bottom: 25px;
  right: 10px;
  height: 40px;
  width: 40px;
  z-index: 99;
  border: none;
  outline: none;
  background: #777;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 3px;
  transition: 0.3s;
  text-align: center;
  font-size: 20px;
  color: #fff;
  line-height: 1em;

  &:hover {
    background: $color-blue;
  }

  &::before {
    content: "";
    border: 1px solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-135deg);
  }
}
