.partners {
  margin-top: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $media-phone) {
    margin-top: 55px;
    flex-direction: column;
    align-items: start;
  }

  &__img {
    display: flex;
    flex-direction: column;
    justify-content: end;

    img {
      width: 100%;

      @media screen and (max-width: $media-phone) {
        margin-top: 25px;
      }
    }

    img:last-of-type {
      margin-top: 25px;

      @media screen and (max-width: $media-phone) {
        margin-top: 15px;
      }
    }
  }
}
