.header {
  background-image: url("./assets/images/back-header.png");
  background-size: cover;
  padding: 42px 0 117px;
  position: relative;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(68, 79, 180, 0.7);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  @media screen and (max-width: $media-phone) {
    padding: 26px 0 100px;
    background-image: url("./assets/images/back-header-mobile.png");
  }

  &__content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $media-tablet) {
      flex-direction: column;
    }

    @media screen and (max-width: $media-phone) {
      flex-direction: initial;
    }

    &_logo {
      img {
        width: 252px;

        @media screen and (max-width: $media-phone) {
          width: 190px;
        }
      }
    }

    &_navigation {
      @media screen and (max-width: $media-phone) {
        display: none;
      }

      .nav {
        .item {
          font-size: 14px;
          line-height: 44px;
          color: #fff;
          font-weight: 500;
          margin-left: 25px;
        }
      }
    }

    &_contacts {
      display: flex;
      align-items: center;

      @media screen and (max-width: $media-phone) {
        display: none;
      }

      .item {
        display: flex;
        align-items: center;
        margin-left: 20px;

        .wrapper-icon {
          display: flex;
          background: rgba(255, 255, 255, 0.08);
          border-radius: 6px;
          padding: 6px 5px;
        }

        a {
          color: #fff;
        }

        .email {
          font-size: 14px;
          line-height: 30px;
          font-weight: 400;
          margin-left: 9px;
        }

        .phone {
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          margin-left: 14px;
        }
      }
    }

    .burger {
      width: 25px;
      height: 20px;
      position: absolute;
      background: transparent;
      border: none;
      display: none;
      transition: all 0.3s;
      z-index: 999;
      right: 0;
      top: 10px;

      @media screen and (max-width: $media-phone) {
        display: block;
      }

      &__line {
        width: 100%;
        height: 2px;
        background: #ffffff;
        position: absolute;
        left: 0;
        transition: all 0.3s;
        border-radius: 2px;
      }

      &__line_top {
        top: 0;
      }

      &__line_middle {
        top: 50%;
        transform: translateY(-50%);
      }

      &__line_bottom {
        bottom: 0;
      }
    }

    .burger.active {
      position: absolute;

      .arrow {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 6px;
      }

      .down {
        transform: rotate(45deg);
      }
    }
  }

  &__content-bottom {
    padding-top: 115px;
    text-align: center;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $media-phone) {
      padding-top: 90px;
    }

    .header-title {
      font-size: 38px;
      line-height: 48px;
      color: #fff;
      font-weight: 500;
      margin-bottom: -50px;

      @media screen and (max-width: $media-phone) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: -15px;
      }
    }

    .logo-big {
      width: 842px;

      @media screen and (max-width: $media-phone) {
        width: 100%;
        max-width: 419px;
      }
    }

    .paragraph {
      font-size: 24px;
      line-height: 44px;
      color: #fff;
      font-weight: 500;
      margin-top: -80px;

      @media screen and (max-width: $media-phone) {
        font-size: 16px;
        line-height: 26px;
        margin-top: -35px;
      }
    }

    .button-position {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 17px;

      @media screen and (max-width: $media-phone) {
        flex-direction: column;
      }

      .button:last-of-type {
        margin-left: 20px;

        @media screen and (max-width: $media-phone) {
          margin: 15px 0 0;
        }
      }
    }
  }
}
